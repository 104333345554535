<template>
<div></div>
</template>

<script>
    export default {
        // eslint-disable-next-line
        name: 'Menu'
    }
</script>

<style scoped>

</style>
