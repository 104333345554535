<template>
    <ul class="vuejs-countdown">
        <li v-if="days > 0">
            <p class="digit">{{ days | twoDigits }}</p>
            <p class="text">{{ days > 1 ? 'days' : 'day' }}</p>
        </li>
        <li>
            <p class="digit">{{ hours | twoDigits }}</p>
            <p class="text">{{ hours > 1 ? 'hours' : 'hour' }}</p>
        </li>
        <li>
            <p class="digit">{{ minutes | twoDigits }}</p>
            <p class="text">min</p>
        </li>
        <li>
            <p class="digit">{{ seconds | twoDigits }}</p>
            <p class="text">Sec</p>
        </li>
    </ul>
</template>

<script>
    import Moment from 'moment'

    let interval = null
    export default {
        // eslint-disable-next-line
        name: "Countdown",
        props: {
            deadline: {
                default: function () {
                    if (this.$route.params.time !== undefined) {
                        return this.$route.params.time
                    }
                    return ' '
                },
                type: String
            },
            end: {
                type: String
            },
            stop: {
                type: Boolean
            }
        },
        data() {
            return {
                now: Moment().unix(),
                date: null,
                diff: 0
            }
        },
        created() {
            if (!this.deadline && !this.end) {
                throw new Error("Missing props 'deadline' or 'end'")
            }
            this.date = new Moment(this.$store.state.time)
            if (!this.date) {
                throw new Error("Invalid props value, correct the 'deadline' or 'end'")
            }
            interval = setInterval(() => {
                this.now = Moment()
            }, 1000)
        },
        computed: {
            seconds() {
                return Math.trunc(this.diff) % 60
            },
            minutes() {
                return Math.trunc(this.diff / 60) % 60
            },
            hours() {
                return Math.trunc(this.diff / 60 / 60) % 24
            },
            days() {
                return Math.trunc(this.diff / 60 / 60 / 24)
            }
        },
        watch: {
            now() {
                this.diff = this.date.unix() - this.now.unix()
                if (this.diff <= 0 || this.stop) {
                    this.diff = 0
                    // Remove interval
                    clearInterval(interval)
                }
            },
            end(value) {
                this.date = new Moment(value)
            }
        },
        filters: {
            twoDigits(value) {
                if (value.toString().length <= 1) {
                    return '0' + value.toString()
                }
                return value.toString()
            }
        },
        destroyed() {
            clearInterval(interval)
        }
    }
</script>

<style scoped>
    h1, h2 {
        font-weight: normal;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }

    .vuejs-countdown {
        padding: 0;
        margin: 0;
    }

    .vuejs-countdown li {
        display: inline-block;
        margin: 0 8px;
        text-align: center;
        position: relative;
    }

    .vuejs-countdown li p {
        margin: 0;
    }

    .vuejs-countdown li:after {
        content: ":";
        position: absolute;
        top: 0;
        right: calc(((72px + (120 - 72) * ((100vw - 300px) / (1600 - 300))) * 0.25) * -1);
        font-size: calc(72px + (120 - 72) * ((100vw - 300px) / (1600 - 300)));
    }

    .vuejs-countdown li:first-of-type {
        margin-left: 0;
    }

    .vuejs-countdown li:last-of-type {
        margin-right: 0;
    }

    .vuejs-countdown li:last-of-type:after {
        content: "";
    }

    .vuejs-countdown .digit {
        font-size: calc(72px + (120 - 72) * ((100vw - 300px) / (1600 - 300)));
        font-weight: 600;
        line-height: 1.4;
        margin-bottom: 0;
    }

    .vuejs-countdown .text {
        text-transform: uppercase;
        margin-bottom: 0;
        font-size: calc(16px + (40 - 16) * ((100vw - 300px) / (1600 - 300)));
    }
</style>
